<template>
    <div>
        <table class="expand_table">
            <tr v-show="row.reason_summary">
                <td class="expand-td-left">事故原因简述: </td>
                <td class="expand-td-right">{{ row.reason_summary }}</td>
            </tr>
            <tr v-show="row.position">
                <td class="expand-td-left">事故地点: </td>
                <td class="expand-td-right">{{ row.position }}</td>
            </tr>
            <tr v-show="row.responsibility">
                <td class="expand-td-left">责任单位: </td>
                <td class="expand-td-right">{{ row.responsibility }}</td>
            </tr>
            <tr v-show="row.five_minutes_delay_event">
                <td class="expand-td-left">5分钟晚点事件: </td>
                <td class="expand-td-right">{{ row.five_minutes_delay_event }}</td>
            </tr>
            <tr v-show="row.five_minutes_delay_train">
                <td class="expand-td-left">5分钟晚点列次: </td>
                <td class="expand-td-right">{{ row.five_minutes_delay_train }}</td>
            </tr>
        </table>
    </div>
</template>
<script>
    export default {
        name: "accidentListExpand",
        props: {
            row: Object
        }
    }
</script>
<style scoped>
    .wrapper{
        text-align: center;
    }
    .expand_table {
        margin: 0 auto;
        width:80%;
        border-collapse: collapse;
    }
    .expand-td-left{
        padding: 0 20px;
        width:150px;
        text-align: right;
        background-color: inherit;
        border: none;
        color:#888;
    }
    .expand-td-right{
        background-color: inherit;
        border: none;
        font-size: 14px;
    }
</style>