<template>
  <data-view :columnsFormat="columnsFormat" :askData="op_name" @refreshDo="chartRefresh">
    <template v-slot:default="dv_props">
      <el-tab-pane>
        <span slot="label" class="pane_label">
          <Icon type="ios-pie" />占比图
        </span>
        <div class="echarts-wrapper">
          <div class="single-chart-wrapper">
            <continue-period
              :choosedOptions="dv_props.choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></continue-period>
          </div>
          <div class="single-chart-wrapper">
            <reason-point
              :choosedOptions="dv_props.choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></reason-point>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label" class="pane_label">
          <Icon type="ios-trending-up" />趋势图
        </span>
        <div class="single-chart-wrapper">
          <year-trend
            :choosedOptions="dv_props.choosedOptions"
            :drawAgain="updateCount"
            :esize="echartSize"
          ></year-trend>
        </div>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label" class="pane_label">
          <Icon type="md-clipboard" />故障风险流程示意图
        </span>
        <div class="ins-wrapper">
          <img class="navImg" :src="imgUrl['故障风险流程示意图']" alt />
        </div>
        <accident-list-expand v-if="false"></accident-list-expand>
      </el-tab-pane>
    </template>
  </data-view>
</template>
<script>
import dataView from "@/components/Common/DataView.vue";
import accidentListExpand from "./AccidentListExpand.vue";
import yearTrend from "./Charts/YearTrend.vue";
import reasonPoint from "./Charts/ReasonPoint.vue";
import continuePeriod from "./Charts/ContinuePeriod.vue";

export default {
  name: "accidentList",
  props: [],
  components: {
    dataView,
    accidentListExpand,
    yearTrend,
    reasonPoint,
    continuePeriod
  },
  data() {
    return {
      op_name: this.$options.name,
      imgUrl: {
        '故障风险流程示意图': process.env.BASE_URL + "img/故障风险流程示意图.jpg"
      },
      updateCount: 1,
      echartSize: { height: "700px", width: "1000px" },
      columnsFormat: [
        {
          type: "expand",
          width: 30,
          render: (h, params) => {
            return h(accidentListExpand, {
              props: {
                row: params.row
              }
            });
          }
        },
        /*{
                        title: '所属城市',
                        key: 'city',
                        width:100,
                        align: "center"
                    },*/
        {
          title: "线路名称",
          key: "line_name",
          width: 100,
          align: "center",
          render: (h, p) => {
            let url = "/baseInfo/line_c/" + p.row.line_id;
            return h(
              "a",
              {
                attrs: {
                  href: url,
                  target: "_blank"
                }
              },
              p.row.line_name
            );
          }
        },
        {
          title: "发生时间",
          key: "happen_time",
          width: 150,
          align: "center"
        },
        {
          title: "持续时长/min",
          key: "continue_period",
          sortable: true,
          width: 150,
          align: "center",
          filters: [
            {
              label: "5~15分钟",
              value: 1
            },
            {
              label: "15～30分钟",
              value: 2
            },
            {
              label: "30分钟以上",
              value: 3
            }
          ],
          filterMultiple: false,
          filterMethod(value, row) {
            if (value === 1) {
              return row.continue_period <= 15;
            } else if (value === 2) {
              return row.continue_period > 15 && row.continue_period < 30;
            } else if (value === 3) {
              return row.continue_period > 30;
            }
          }
        },
        {
          title: "事件简述",
          key: "description",
          align: "center",
          tooltip: "true",
          minWidth: 150
          /*render:(h,p)=>{
                            let ob = {name:"accident_c",params:{accident_id:p.row.id}}
                            return h('router-link',{
                            attrs:{
                                to:ob                                
                            }},p.row.description)
                        }*/
        },
        {
          title: "归因分类",
          key: "reason_interface",
          width: 100,
          align: "center"
        },
        {
          title: "具体归因分类",
          key: "reason_point",
          width: 120,
          align: "center"
        },
        {
          title: "险性事件",
          key: "if_danger",
          width: 90,
          align: "center"
        }
      ]
    };
  },
  methods: {
    chartRefresh() {
      this.updateCount++;
    }
  }
};
</script>
<style scoped>
.single-chart-wrapper{
  margin-top:10px;
}
</style>